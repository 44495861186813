/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react'

// Images
import gif from 'img/Clearmind_Intro.gif'
import exxesGif from 'img/Clearmind_Exxes.gif'
import imgPopup from 'img/clearmind-pop-up.jpg'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import Solutions from 'components/shared/Solutions'
import ShadowBox from 'components/shared/ShadowBox'
import BrancheLinks from 'components/shared/BrancheLinks'
import Quotes from 'components/shared/Quotes'
import HeroCopy from 'components/shared/HeroCopy'
import NewsOverview from 'components/shared/NewsOverview'
import Experience from 'components/shared/Experience'
import ButtonDefault from 'components/elements/ButtonDefault'
import Modal from 'components/elements/Modal'

// Third Party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ModalVacancies from '../components/elements/ModalVacancies'

const StyledModal = styled(motion.div)`
  position: fixed;
  height: fit-content;
  width: 600px;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.color.face.light};
  border-radius: 33px;
  z-index: 99999998;
  padding: 40px;

  & h2 {
    text-align: center;
    font-weight: ${(props) => props.theme.font.weight.xl};
    padding-bottom: 20px;
  }

  & p {
    text-align: center;
  }

  & img {
    width: 100%;
    height: auto;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 991px) {
    width: calc(100% - 30px);
    padding: 20px;
  }
`

const ImageContainer = styled.div``

const Background = styled(motion.div)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 99999997;
  cursor: pointer;
`

const Shadow = styled.div`
  z-index: 99999999;
  position: absolute;
  background: rgb(255, 255, 255);
  background: -moz-radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const HomeTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast },
  },
  location,
}) => {
  const [isOpen, setOpen] = useState(false)
  const [isSecondOpen, setSecondOpen] = useState(false)

  useEffect(() => {
    if (location.hash === '#breedband-is-clearmind') {
      setOpen(true)
    }
    if (location.hash === '#exxes-is-clearmind') {
      setSecondOpen(true)
    }
  }, [])

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero className="mb-lg-5" thicc content={acf.banner} />

      <AnimatePresence>
        {isOpen && (
          <>
            <Background
              onClick={() => setOpen(false)}
              initial={{ backgroundColor: 'rgba(0,0,0,0)' }}
              animate={{ backgroundColor: 'rgba(0,0,0,.6)' }}
              exit={{ backgroundColor: 'rgba(0,0,0,0)' }}
            />
            <StyledModal
              initial={{ y: -100, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { delay: 0.9, type: 'spring', damping: 20 },
              }}
              exit={{ y: 200, opacity: 0 }}
            >
              <ImageContainer className="position-relative">
                <img src={gif} alt="" />
                <Shadow />
              </ImageContainer>

              <h2>
                Breedband in Bedrijf is voortaan ClearMind IT Consultancy!
              </h2>
              <p>
                Dezelfde kwaliteit die u van ons gewend bent in een nieuw jasje.
                We blijven door innovatie in ICT vooruitgang boeken, zodat we
                morgen beter zijn dan vandaag. Ervaar onze Experience
                Excellence.
              </p>

              <div className="d-flex pt-4 justify-content-center">
                <div className="cursor-pointer" onClick={() => setOpen(false)}>
                  <ButtonDefault to="/">Bekijk onze website</ButtonDefault>
                </div>
              </div>
            </StyledModal>
          </>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isSecondOpen && (
          <>
            <Background
              onClick={() => setOpen(false)}
              initial={{ backgroundColor: 'rgba(0,0,0,0)' }}
              animate={{ backgroundColor: 'rgba(0,0,0,.6)' }}
              exit={{ backgroundColor: 'rgba(0,0,0,0)' }}
            />
            <Modal
              initial={{ y: -100, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { delay: 0.9, type: 'spring', damping: 20 },
              }}
              exit={{ y: 200, opacity: 0 }}
            >
              <ImageContainer className="position-relative">
                <img src={exxesGif} alt="" />
                <Shadow />
              </ImageContainer>

              <h2>Exxes is voortaan ClearMind IT Consultancy!</h2>
              <p>
                Dezelfde kwaliteit die u van ons gewend bent in een nieuw jasje.
                We blijven door innovatie in ICT vooruitgang boeken, zodat we
                morgen beter zijn dan vandaag. Ervaar onze Experience
                Excellence.
              </p>

              <div className="d-flex pt-4 justify-content-center">
                <div
                  className="cursor-pointer"
                  onClick={() => setSecondOpen(false)}
                >
                  <ButtonDefault to="/">Bekijk onze website</ButtonDefault>
                </div>
              </div>
            </Modal>
          </>
        )}
      </AnimatePresence>

      {/* <Solutions className="py-lg-5" /> */}

      <Experience content={acf.experience_excellence} />

      <div className="container py-5">
        <div className="row py-5">
          <div className="col-lg-6 d-flex justify-content-center justify-content-lg-start">
            <ShadowBox content={acf.branches} />
          </div>
          <div className="col-lg-6 pt-5 pt-lg-4 d-flex align-items-center justify-content-center justify-content-lg-end">
            <BrancheLinks />
          </div>
        </div>
      </div>

      <Quotes content={acf.quotes} />

      <HeroCopy className="mb-5" content={acf.working_together} />

      <NewsOverview home className="pt-lg-5" />

      <ModalVacancies image={imgPopup} />
    </Layout>
  )
}

export default HomeTemplate
